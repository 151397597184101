
.title-wrap[data-v-18f60038] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 44px;
  padding: 0 15px;
  border: 1px solid #EBEFF2;
  margin-bottom: 20px;
}
.title-wrap h3[data-v-18f60038] {
  margin: 0!important;
  font-size: 16px;
  font-weight: bold;
}
.title-wrap span[data-v-18f60038] {
   color: #999;
   padding:0 5px;
   font-size: 16px;
}
.title[data-v-18f60038] {
  color: #999;
    border-left: 4px solid #fff;
    text-align: left;
    padding-left: 10px;
    font-weight: bold;
    line-height: 20px;
    font-size: 20px;
    margin-bottom: 20px;
    cursor: pointer;
}
.top[data-v-18f60038] {
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
}
.top span[data-v-18f60038] {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    text-align: left;
    padding-left: 20px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}
.product[data-v-18f60038] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-left: 20px;
    padding-bottom: 15px;
}
.p-l[data-v-18f60038] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    text-align: left;
    border-right: 1px solid #EBEFF2;
    height: 84px;
    position: relative;
}
.p-l p[data-v-18f60038] {
  margin-bottom: 0;
  font-size: 12px;
}
.pro-tit-wrap[data-v-18f60038] {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.pro-tit[data-v-18f60038] {
  padding-right: 60px;
}
.p-l img[data-v-18f60038] {
  width: 142px;
    min-width: 142px;
    height: 84px;
    border: 1px solid #eee;
    border-radius: 6px;
    margin-right: 20px;
    -o-object-fit: cover;
       object-fit: cover;
}
.course[data-v-18f60038] {
    border: 1px solid #eee;
    margin-bottom: 20px;
    border-top: 2px solid #3091FD;
}
.price[data-v-18f60038] {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    color: #FC583D;
    font-size: 28px;
    text-align: left;
    padding-left: 20px;
}
.ant-tag[data-v-18f60038] {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    text-align: center;
    line-height: 30px;
}
.tag[data-v-18f60038] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.tag img[data-v-18f60038] {
  width: 22px;
  height: 22px;
}
.tag p[data-v-18f60038] {
  margin-bottom: 0;
}
.info[data-v-18f60038] {
    border-bottom: 1px solid #EFEFEF;
    padding: 20px 0;
    border-radius: 4px;
    margin-bottom: 20px;
    background: #eee;
}
.info>div[data-v-18f60038] {
  margin-bottom: 10px;
}
.item[data-v-18f60038] {
  border: 1px solid #EBEFF2;
  border-top: 2px solid #C2CED3;
  height: 170px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 20px;
  padding: 10px 0;
}
.item>div[data-v-18f60038] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  padding-left: 20px;
  height: 100%;
}
.item>div[data-v-18f60038]:first-child {
  border-right: 1px solid #EBEFF2;
}
.item>div>div[data-v-18f60038] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 12px;
  padding: 8px 0;
  width: 100%;
}
.item>div>div>span[data-v-18f60038]:last-child {
  color: #666;
  margin-left: 20px;
}
.txt-info[data-v-18f60038] {
  border-bottom: 1px solid #EFEFEF;
  margin-bottom: 20px;
}
.txt-info>div[data-v-18f60038] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 15px;
}
.txt-info>div>label[data-v-18f60038] {
  margin-right: 10px;
  min-width: 70px;
}
.refundTxt[data-v-18f60038] {
  margin-bottom: 60px;
}
.refundTxt>div[data-v-18f60038] {
  margin-bottom: 20px;
}
.refundTxt>div>span[data-v-18f60038]:first-child {
  color: #333;
}
.refundTxt>div>span[data-v-18f60038]:last-child {
  color: #666;
}
.refund-btn[data-v-18f60038] {
  text-align: center;
}
.modal-top[data-v-18f60038] {
  border-bottom: 1px solid #EFEFEF;
  margin-bottom: 20px;
}
.modal-top .input-item[data-v-18f60038] {
  margin-bottom: 20px;
}
.modal-top .input-item>div[data-v-18f60038] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 20px;
}
.modal-top .input-item>div>label[data-v-18f60038] {
  min-width: 50px;
  font-size: 12px;
  margin-right: 20px;
  font-weight: bold;
}
.modal-bot[data-v-18f60038] {
  margin-bottom: 30px;
}
.modal-bot .invoice-title[data-v-18f60038]{
  font-size: 12px;
  font-weight: bold;
}
.modal-bot .content[data-v-18f60038] {
  height: 220px;
  overflow-y: auto;
}
.modal-bot .content[data-v-18f60038]::-webkit-scrollbar, .player-list[data-v-18f60038]::-webkit-scrollbar {
  width:3px;
}
.modal-bot .content[data-v-18f60038]::-webkit-scrollbar-thumb, .player-list[data-v-18f60038]::-webkit-scrollbar-thumb{
    background-color:#aaa;
}
.invoice-btn[data-v-18f60038] {
  text-align: center;
}
.see-invoice[data-v-18f60038] {
  border-bottom: 1px solid #EFEFEF;
  margin-bottom: 20px;
}
.see-invoice>div[data-v-18f60038] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 20px;
}
.see-invoice>div>label[data-v-18f60038] {
  font-weight: bold;
}
.see-invoice>div>p[data-v-18f60038] {
  margin: 0 0 0 20px;
}
.invoice-img[data-v-18f60038] {
  margin-bottom: 30px;
}
.invoice-img-title[data-v-18f60038] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.invoice-img img[data-v-18f60038] {
  width: 100%;
  height: 298px;
}
