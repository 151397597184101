
.download-item[data-v-7e2c628c] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px dashed #A6B7BF;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.download-item img[data-v-7e2c628c] {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}
.download-item-left[data-v-7e2c628c], .download-item-right[data-v-7e2c628c] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.download-item-left span[data-v-7e2c628c] {
  font-size: 14px;
  color: #333;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.download-item-right span[data-v-7e2c628c] {
  font-size: 12px;
  color: #666;
  margin-right: 20px;
}
.download-item-right a[data-v-7e2c628c] {
  width: 80px;
  display: block;
  height: 24px;
  line-height: 24px;
  background: #0071F5;
  color: #fff;
  font-size: 12px;
  padding: 0;
  border-radius: 12px;
  border: none;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABFUlEQVQ4T7WTLUtEQRiFnyeKUewaFDEJalDEj2AzWPwFgsWgRSxmo4v4BywGsYiCFoMIYtlsMljEZNEsR+6yV9a764IsDgzDvHPmmfO+MyM9NnvcT0dAkjWg6DX1odshbYAkAY6BK2AXGFYHfoP8ACSpq1Ot4iSzwH1LbEm9LeffgCQbQL9a62Y5yQuwrZ4VugYgyRywDxyVC834ItCnXldcRW3sNckBsA6Mqa8V4QIwoR5WAcCyelMAiqKNqE8V0TgwCIwWNVAfK+uXwEoDUNopBUmGgHNgqwnZA1bV57ZUOgFaQHeNPHW+U2GTvBUOLoBT9eQvrzLJNLBT3kIdmGwCPoB3oNs4A3wCm//zF/6SSs8OvgA083a+q+WbLQAAAABJRU5ErkJggg==);
  padding-left: 18px;
  background-repeat: no-repeat;
  background-position: 5px center;
  background-size: 16px 16px;
}
.empty[data-v-7e2c628c] {
  padding: 50px 0;
  width: 100%;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.empty img[data-v-7e2c628c] {
  width: 138px;
  height: 116px;
  margin-bottom: 20px;
}
.empty span[data-v-7e2c628c] {
  font-size: 12px;
  color: #666;
}
