
.bg[data-v-aa7ef86c] {
    width: 100%;
    position: relative;
    height: 100%;
}
.codes[data-v-aa7ef86c] {
    width:180px;
    height: 180px;
    position: absolute;
    left: 20%;
    top: 50%;
    border-radius: 100px;
}
.code[data-v-aa7ef86c] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
