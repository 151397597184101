
.testify[data-v-908efcfa] {
    padding: 20px;
    border: 1px solid #eee;
}
.loadding[data-v-908efcfa] {
  height: 500px;
}
.title[data-v-908efcfa] {
  border-left: 4px solid #fff;
  text-align: left;
  padding-left: 10px;
  font-weight: bold;
  line-height: 20px;
  font-size: 16px;
  margin-bottom: 20px;
}
table[data-v-908efcfa] {
    width: 100%;
    min-width: 100%;
    margin-bottom: 20px;
}
table td[data-v-908efcfa] {
    border: 1px solid #999;
    height: 44px;
}
table .line li[data-v-908efcfa]{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border-bottom: 1px solid #ccc;
}
table .line li p[data-v-908efcfa] {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    text-align: left;
    padding-left: 50px;
    margin: 0;
    line-height: 40px;
    height: 40px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
.fw[data-v-908efcfa] {
    font-weight: bold;
}
